@tailwind base;
@tailwind components;
@tailwind utilities;
/* @tailwind variants; */

/* Altera a cor da barra de rolagem */
::-webkit-scrollbar {
  width: 10px; /* Largura da barra de rolagem */
  height: 8px; /* Altura da barra de rolagem */
  background-color: #404040; /* Cor de fundo da barra de rolagem */
}

/* Altera a cor do indicador (thumb) */
::-webkit-scrollbar-thumb {
  background-color: #404040; /* Cor do indicador (thumb) */
}

.gradient-02 {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 300px;
  left: 0px;

  background: #ff7315;
  filter: blur(190px);
}

.gradient-03 {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0px;
  right: 0px;

  background: #ff7315;
  filter: blur(190px);
}

.gradient-04 {
  position: absolute;
  width: 100px;
  height: 100px;
  bottom: -50px;
  left: 0px;

  background: #ff7315;
  filter: blur(190px);
}

.gradient-05 {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 450px;
  right: 0px;

  background: #ff7315;
  filter: blur(190px);
}
